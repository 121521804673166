import { acceptHMRUpdate, defineStore } from 'pinia';
import { findDownloadIdInSlug } from './helper/findDownloadIdInSlug';
import type {
  DownloadsQueryModel,
  DownloadsResponseModel
} from '~/server/api/downloads/index.get';
import { type DownloadsPerCategoryQueryModel } from '~/server/api/downloads/perCategory.get';
import { isDefined } from '~/utils/guards/isDefined';
import type { DownloadEntryModel } from '~/lib/DownloadService/model/DownloadEntryModel';
import type { LocationQuery, RouteParams } from 'vue-router';
import type {
  DownloadsFacetSearchQueryModel,
  DownloadsFacetSearchResponseModel
} from '~/server/api/downloads/facetSearch';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';
import { withReset } from '~/utils/pinia/withReset';
import type {
  DownloadByIdQueryModel,
  DownloadByIdResponseModel
} from '~/server/api/downloads/byId.get';
import type {
  DownloadsExistQueryModel,
  DownloadsExistResponseModel
} from '~/server/api/downloads/exist.get';

export type DownloadStoreState = {
  context: 'documentCenter' | 'pdp';
  query: string;
  staticFilters: Record<string, string[]>;
  filters: Record<string, string[]>;
  loading: boolean | undefined;
  activeModal: string | undefined | boolean;
  download: DownloadEntryModel | undefined;
  downloads: DownloadsResponseModel | null;
  offsetCounter: Record<string, number>;
  documentationLink: Record<string, string> | undefined;
  facetSearchAbortController: AbortController | null;
};

export const FILTER_TYPES_WITH_SEARCH = ['products', 'series'];

const CATEGORY_ANALYTICS_MAP: Record<string, string> = {
  'downloadCategory.data': 'document-type_filter_selection',
  'medialink.locale': 'document-lang_filter_selection',
  products: 'document-product_filter_selection',
  series: 'document-series_filter_selection'
};

export const useDownloadStore = defineStore('downloads', () => {
  const context = ref<'documentCenter' | 'pdp'>('documentCenter');
  const query = ref<string>('');
  const staticFilters = ref<Record<string, string[]>>({});
  const filters = ref<Record<string, string[]>>({});
  const loading = ref<boolean | undefined>(undefined);
  const activeModal = ref<string | undefined | boolean>(false);
  const download = ref<DownloadEntryModel | undefined>(undefined);
  const downloads = ref<DownloadsResponseModel | null>(null);
  const offsetCounter = ref<Record<string, number>>({});
  const documentationLink = ref<Record<string, string> | undefined>({});
  const facetSearchAbortController = ref<AbortController | null>(null);

  const _state = {
    context,
    query,
    staticFilters,
    filters,
    loading,
    activeModal,
    download,
    downloads,
    offsetCounter,
    documentationLink,
    facetSearchAbortController
  };

  const mergedFilters = computed(() => {
    return {
      ...filters.value,
      ...staticFilters.value
    };
  });

  const hasResults = computed<boolean>(() =>
    Object.values(downloads.value?.results ?? {}).some(
      (values) => !isEmpty(values)
    )
  );

  const validFilters = computed<Record<string, string[]>>(() => {
    const validKeys = downloads.value?.filters.map(({ name }) => name) ?? [];

    return Object.fromEntries(
      Object.entries(filters.value).filter(([key]) => validKeys.includes(key))
    );
  });

  const hasActiveFilters = computed<boolean>(() =>
    Object.values(filters.value).some((values) => !isEmpty(values))
  );

  async function updateFiltersFromRoute(
    routeQuery: LocationQuery
  ): Promise<void> {
    const routeFilters: Record<string, string[]> = {};

    for (const key in routeQuery) {
      // "sort" inside an encoded json in an url (e.g. filter={"sort" ...})
      // will lead to a firewall error. therefore, we ignore this parameter.
      // @see https://gcp.baslerweb.com/jira/browse/DBP-988
      if (key === 'sort') {
        continue;
      }

      const value = routeQuery[key];
      routeFilters[key] = ensureArray(value)
        .map((value) => value?.toString())
        .filter(isDefined);
    }

    if (JSON.stringify(filters.value) !== JSON.stringify(routeFilters)) {
      filters.value = routeFilters;
    }
  }

  /**
   * Handles a route change to determine any active download id.
   *
   * @param params The new route params.
   */
  async function handleRouteChange(params: RouteParams) {
    const { $globalPageSettings } = useNuxtApp();
    const documentCenterSlug =
      $globalPageSettings.value?.documentCenterPage?.metadata?.slug;
    if (!documentCenterSlug) {
      return;
    }

    const downloadId = findDownloadIdInSlug(
      ensureArray(params.slug),
      documentCenterSlug
    );

    if (downloadId) {
      await openModal(downloadId);
    } else {
      activeModal.value = undefined;
    }
  }

  async function openModal(downloadId: string): Promise<void> {
    if (downloads.value?.results) {
      for (const key in downloads.value?.results) {
        downloads.value?.results[key].hits.filter((downloadItem) => {
          if (downloadId === downloadItem.id) {
            download.value = downloadItem;

            return;
          }
        });
      }
    }

    if (!download.value) {
      await fetchDownloadById(downloadId);
    }

    activeModal.value = downloadId;
  }

  async function fetchDownloads(): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();
    loading.value = true;

    // XXX: abort controller
    // XXX: error handling
    downloads.value = await $fetch<DownloadsResponseModel>('/api/downloads', {
      method: 'get',
      query: {
        query: query.value,
        locale: $resolvedLocale.value!,
        filters: JSON.stringify(mergedFilters.value)
      } satisfies DownloadsQueryModel
    });

    // init pageCounter
    offsetCounter.value = Object.keys(downloads.value.results).reduce(
      (reducer, category) => {
        reducer[category] = 1;

        return reducer;
      },
      {} as Record<string, number>
    );
    loading.value = false;
  }

  async function checkForExistingDownloads(sku: string): Promise<boolean> {
    const { $resolvedLocale } = useNuxtApp();

    // XXX: abort controller
    // XXX: error handling
    const { exist } = await $fetch<DownloadsExistResponseModel>(
      '/api/downloads/exist',
      {
        method: 'get',
        query: {
          locale: $resolvedLocale.value!,
          sku
        } satisfies DownloadsExistQueryModel
      }
    );

    return exist;
  }

  async function fetchDownloadById(id: string): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();
    const logger = useLogger();

    try {
      download.value = await $fetch<DownloadByIdResponseModel>(
        '/api/downloads/byId',
        {
          method: 'get',
          query: {
            id,
            locale: $resolvedLocale.value!
          } satisfies DownloadByIdQueryModel
        }
      );
    } catch (e) {
      logger.error(`could not load document download by id "${id}"`, e);
    }
  }

  async function fetchMore(category: string): Promise<void> {
    const { $resolvedLocale } = useNuxtApp();

    offsetCounter.value[category] =
      downloads.value?.results?.[category]?.hits.length ?? 0;

    // XXX: abort controller
    // XXX: error handling
    const moreDownloads = await $fetch<DownloadsResponseModel>(
      '/api/downloads/perCategory',
      {
        method: 'get',
        query: {
          query: query.value,
          locale: $resolvedLocale.value!,
          filters: JSON.stringify(mergedFilters.value),
          category: category,
          limit: '5',
          offset: offsetCounter.value[category]?.toString() ?? '0'
        } satisfies DownloadsPerCategoryQueryModel
      }
    );

    if (
      downloads.value &&
      downloads.value.results &&
      category in downloads.value.results
    ) {
      // spread more downloads into existing ones per category
      downloads.value.results[category].hits = [
        ...downloads.value.results[category].hits,
        ...moreDownloads.results[category].hits
      ];
    }
  }

  function handleFormValueChange(key: string, value: string | string[]) {
    const { $analytics } = useNuxtApp();

    filters.value = {
      ...filters.value,
      [key]: ensureArray(value)
    };
    $analytics?.pushToDataLayer({
      action: CATEGORY_ANALYTICS_MAP[key],
      category: 'downloads_filter_selection',
      event: 'click_Internal',
      label: value
    });
    updateRoute(filters.value);
  }

  function updateRoute(filters: Record<string, string[]>) {
    const router = useRouter();

    router.push(createUrlParams(filters));
  }

  function createUrlParams(newFilters?: Record<string, string[]>) {
    const clonedFilters = _cloneFilters(newFilters ?? filters.value);

    return {
      query: clonedFilters
    };
  }

  function _cloneFilters(
    newFilters?: Record<string, string[]>
  ): Record<string, string[]> {
    return JSON.parse(JSON.stringify(newFilters ?? filters.value));
  }

  function resetFilters() {
    filters.value = {};
    updateRoute(filters.value);
  }

  function createNewUrlParamsFromFilter(key: string, valueToRemove: string) {
    const clonedFilters = _cloneFilters();
    const values = filters.value[key];
    const arrayValues = ensureArray(values).filter(isDefined).map(String);

    clonedFilters[key] = arrayValues.filter(
      (value) => value && !valueToRemove.includes(value)
    );

    return createUrlParams(clonedFilters);
  }

  function closeModal(): void {
    activeModal.value = false;
  }

  function afterCloseModal(): void {
    const logger = useLogger();
    const router = useRouter();

    if (context.value === 'pdp') {
      router
        .replace({
          hash: '#tab-panel-tab-documents',
          query: filters.value,
          replace: true
        })
        .catch((e) =>
          logger.error(
            'failed to navigate during close modal in download store',
            e
          )
        );

      return;
    }

    const locale = useLocale();
    const { $globalPageSettings } = useNuxtApp();
    const path = $globalPageSettings.value?.documentCenterPage?.metadata?.slug;

    if (!path) {
      logger.error('no document center page defined - can not close modal');

      return;
    }

    setTimeout(
      () =>
        router.replace({
          path: buildUrlString(locale.value, path),
          query: filters.value
        }),
      300
    );
  }

  async function facetSearch(
    facet: string,
    facetQuery: string
  ): Promise<DownloadsFacetSearchResponseModel | undefined> {
    if (!facetSearchAbortController.value?.signal.aborted) {
      facetSearchAbortController.value?.abort();
    }

    const abortController = new AbortController();
    facetSearchAbortController.value = abortController;

    const { $resolvedLocale } = useNuxtApp();

    try {
      return await $fetch<DownloadsFacetSearchResponseModel>(
        '/api/downloads/facetSearch',
        {
          ...DEFAULT_FETCH_OPTIONS,
          method: 'get',
          query: {
            facet,
            facetQuery,
            query: query.value,
            locale: $resolvedLocale.value!,
            filters: JSON.stringify(mergedFilters.value)
          } satisfies DownloadsFacetSearchQueryModel,

          signal: facetSearchAbortController.value?.signal
        }
      );
    } catch (e) {
      if (!abortController.signal.aborted) {
        useLogger().error('failed to fetch facets search results', e);
      }
    }
  }

  return withReset({
    _state,
    context,
    query,
    staticFilters,
    filters,
    loading,
    activeModal,
    download,
    downloads,
    offsetCounter,
    documentationLink,
    facetSearchAbortController,
    hasResults,
    validFilters,
    hasActiveFilters,
    updateFiltersFromRoute,
    handleRouteChange,
    openModal,
    fetchDownloads,
    checkForExistingDownloads,
    fetchDownloadById,
    fetchMore,
    handleFormValueChange,
    updateRoute,
    createUrlParams,
    resetFilters,
    createNewUrlParamsFromFilter,
    closeModal,
    afterCloseModal,
    facetSearch
  });
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDownloadStore, import.meta.hot));
}
